import { InjectionKeys } from 'o365-utils';
import { globalState } from 'o365-vue';

// Core components to be included with every mobile app

/* alignment */
import Container from "./components.Container.vue";
import Content from "./components.Content.vue";

/* elements */
import Alert from "./components.Alert.vue";
import Search from "./components.Search.vue";
import Drawer from "./components.Drawer.vue";
import FloatingButton from "./components.FloatingButton.vue";
import TitleBar from "./components.TitleBar.vue";
import DatePicker from "./components.DatePicker.vue";

/* tabs */
import Tab from "./components.tabs.Tab.vue";
import Tabs from "./components.tabs.Tabs.vue";
import TabBar from "./components.tabs.TabBar.vue";
import TabBtn from "./components.tabs.TabBtn.vue";

/* sheets */
import BottomSheet from "./components.BottomSheet.vue";
import ActionSheet from "./components.ActionSheet.vue";
import ActionSheetBtn from "./components.ActionSheetBtn.vue";
import MapSheet from "./components.MapSheet.vue";

/* filter */
import FilterPills from "./components.filter.FilterPills.vue";
import FilterSheets from "./components.filter.FilterSheets.vue";
import FilterButtons from './components.filter.Buttons.vue';

/* visual */
import Card from "./components.Card.vue";
import AppCard from "./components.AppCard.vue";
import Pill from "./components.Pill.vue";
import MSection from "./components.Section.vue";
import Accordion from './components.Accordion.vue';
import AccordionItem from './components.AccordionItem.vue';
import AccordionAddItem from './components.AccordionAddItem.vue';
import RowCount from "./components.RowCount.vue";


export default {
    install(pApp: any, pOptions: MobilePluginOptions) {
        if (pApp.config.globalProperties.$mobileInitalized) { return; }
        pApp.config.globalProperties.$mobileInitalized = true;
        pApp.provide(InjectionKeys.mobileInitalized, true);
        globalState.isMobile = true;
        
        /* alignment */
        pApp.component("MContainer", Container);
        pApp.component("MContent", Content);

        /* elements */
        pApp.component("MAlert", Alert);
        pApp.component("MSearch", Search);
        //app.component("MTabs", Tabs);
        //app.component("MTabs2", Tabs2);
        //app.component("MBottomBar", BottomBar);
        pApp.component("MDrawer", Drawer);
        pApp.component("MFloatingButton", FloatingButton);
        pApp.component("MTitleBar", TitleBar);
        pApp.component("MDatePicker", DatePicker);

        /* tabs */
        pApp.component("MTab", Tab);
        pApp.component("MTabs", Tabs);
        pApp.component("MTabBar", TabBar);
        pApp.component("MTabBtn", TabBtn);

        /* sheets */
        pApp.component("MBottomSheet", BottomSheet);
        pApp.component("MActionSheet", ActionSheet);
        pApp.component("MActionSheetBtn", ActionSheetBtn);
        pApp.component("MMapSheet", MapSheet);
        //app.component("MFilterSheet", FilterSheet);

        /* filter */
        pApp.component("MFilterPills", FilterPills);
        pApp.component("MFilterSheets", FilterSheets);
        pApp.component("MFilterButtons", FilterButtons);
        // app.component("MFilterFields", FilterFields);
        // app.component("MFilterOrders", FilterOrders);

        /* visual */
        pApp.component("MCard", Card);
        pApp.component("MAppCard", AppCard);
        pApp.component("MPill", Pill);
        pApp.component("MSection", MSection);
        pApp.component("MAccordion", Accordion);
        pApp.component("MAccordionItem", AccordionItem);
        pApp.component("MAccordionAddItem", AccordionAddItem);
        pApp.component("MRowCount", RowCount);
    }
};

export type MobilePluginOptions = {

};